module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Welcome to Mehr Banu Gallery, your premier destination for authentic artworks created by Persian artists living around the world. We showcase a diverse collection of paintings, including captivating oil paintings and mesmerizing watercolor art.","short_name":"Mehr Banu Gallery","icon":"src/images/favicon/favicon-180x180.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"908cec0e1a1c7e9154f51f301cd259af"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
